import { Stack, Text, Transition } from "@mantine/core"
const messages = import("../shared/tagErrorMessages")

export const SiteScoreDetails = ({ page, network }) => {
  return (
    <Stack gap={"5px"} p={"xl"}>
      <Text>
        Score: {page.meta.score[network.token].score}/
        {page.meta.score[[network.token]].max}
      </Text>
      <Text>Tags analysis:</Text>
      <ul>
        {page.meta.score[network.token].msgs.map((msg, t) => {
          if (msg.substring(0, 1) === "-") {
            return (
              <li key={"t-" + t}>
                {messages[msg.split(" ")[1]] || msg.split(" ")[1]}
              </li>
            )
          }
        })}
      </ul>
      <span>(Note: currently only images are supported)</span>
    </Stack>
  )
}
